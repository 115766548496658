<template>
    <div>
        <draggable v-if="!dragStop" :id="section.id" :class="{ 'draggable-disabled': queryData === 'view' }" data-source="juju"
            :list="fields" class="list-group flex flex-col gap-2 transition-all duration-200" :draggable=getDragCondition()
            group="feilds" :sort="true" v-bind="dragOptions" @start="isDragging = true" @end="isDragging = false"
            @change="$emit('handleFieldMove', $event)" @add="$emit('handleAddFieldChange', $event)">
            <transition-group type="transition" name="flip-list" class="flex flex-col space-y-4">
                <div :class="{ 'inner-field': !isDragging }" class="list-group-item child item" v-for="element in fields" :key="element.id" @start="isDragging = true" @end="isDragging = false" >
                       
                        <template v-if="element.entity_type === 'field'"  :style="{ 'pointer-events': isDragging ? 'auto' : 'none' }">
                        <template v-if="element.is_cascaded" :style="{ 'pointer-events': isDragging ? 'auto' : 'none','border':'2px solid red' }">
                    {{ log(element) }}
                            <CascadedFields
                            :queryData="queryData" :element="element" :ISEDITPERMISSIONS="ISEDITPERMISSIONS"
                                :section="section" :showSidePanel="showSidePanel" :activeFieldPanel="activeFieldPanel"
                                :allReportsRoles="allReportsRoles"
                                :updateFieldAttributesMethod="updateFieldAttributesMethod"

                                @fieldNameClick="fieldNameClick($event)"
                                @handleDeleteField="handleDeleteField($event.element, $event.section)"
                                @updateFieldLabel="$emit('updateFieldLabel',$event)"
                                @showFieldSettingsModal="showFieldSettingsModal(element, section)"
                                @showGapValidationModal="showGapValidationModal(element, section)"
                                @PermissionChange="PermissionChange"
                                @change="$emit('updateFieldLabel',$event)"
                                @changeCaseCreation="changeCaseCreation(element)"
                                @changeblockVisibile="changeblockVisibile(element,true)" 
                                :outSideBlockVisibility="false"
                                :changeMethod="changeMethod"
                                :changeCaseCreationMethod="changeCaseCreationMethod"
                                :sectionItem="true"
                                />
                        </template>
                        <template v-else :class="{ 'inner-field': true }">
                            <Fields :element="element" :ISEDITPERMISSIONS="ISEDITPERMISSIONS" :section="section"
                                :showSidePanel="showSidePanel" :activeFieldPanel="activeFieldPanel"
                                :allReportsRoles="allReportsRoles"
                                :updateFieldAttributesMethod="updateFieldAttributesMethod"
                                @fieldNameClick="fieldNameClick($event)"
                                @handleDeleteField="handleDeleteField($event.element, $event.section)"
                                @updateFieldLabel="$emit('updateFieldLabel',$event)"
                                @PermissionChange="PermissionChange"
                                @change="$emit('updateFieldLabel',$event)"
                                @changeCaseCreation="changeCaseCreation(element)"
                                @changeblockVisibile="changeblockVisibile(element)"
                                @showGapValidationModal="showGapValidationModal(element, section)" 
                                :changeMethod="changeMethod"
                                :changeCaseCreationMethod="changeCaseCreationMethod"
                                />
                        </template>
                    </template>

                    <!-- Block Dragables -->
                    <div v-else>
                        <div class="bg-card-bg py-3.5 px-3 text-base-content flex justify-between items-center rounded-t-xl handleUpdateBlock"
                            :class="{
                                'bg-blue-200 text-gray-300': element.created,
                                'rounded-xl': !element.expanded,
                            }">
                            <div class="flex items-center gap-10">
                                <h2 class="font-normal text-sm flex items-center gap-3"
                                    :class="element.created && 'text-primary'">
                                    <span v-tippy :content="fetchLabel(element.name)" v-if="element.name && !element.edited"
                                        class="font-semibold">{{ element.name }}</span>
                                    <input v-model="element.name" v-if="element.edited && !element.created"
                                        :class="!element.name ? 'border-b border-dashed border-red-300' : 'border-b border-dashed border-gray-300'"
                                        type="text"
                                        class="bg-transparent min-w-100 border-0 outline-none p-1 text-md focus:bg-gray-100 rounded-md overflow-ellipsis" />
                                    <div v-if="!element.created && ISEDITPERMISSIONS && queryData !== 'view'"
                                        class="h-8 w-8 flex items-center justify-center rounded-half hover:bg-dvbrandcolor hover:text-white-text transition-all duration-150"
                                        :class="element.edited ? 'bg-dvbrandcolor text-white-text' : 'text-primary'"
                                        @click="handleClick(element)">
                                        <font-awesome-icon icon="edit" class="cursor-pointer text-sm fill-current" />
                                    </div>
                                    <span v-tippy :content="fetchLabel(element.label)">{{ element.label }}</span>
                                </h2>
                                <div class="flex gap-5 items-center pr-10" v-if="element.expanded || element.edited">
                                    <div class="flex items-center gap-2 text-sm">
                                        Multi
                                        <Checkbox @click.native.stop v-model="element.block_multi"
                                            :disabled="!element.edited" />
                                    </div>
                                    <div class="flex items-center gap-2 text-sm">
                                        Min
                                        <input @keypress="isNumber($event)" :min="element.block_multi ? 1 : ''" value="1"
                                            type="number" v-model="element.min_count"
                                            :disabled="!element.block_multi || (!element.edited && !element.created)"
                                            class="w-10 h-6 rounded-md text-center font-bold" />
                                    </div>
                                    <div class="flex items-center gap-2 text-sm">
                                        Max
                                        <input @keypress="isNumber($event)"
                                            :min="element.block_multi ? element.min_count : ''" value="1" type="number"
                                            v-model="element.max_count"
                                            :disabled="!element.block_multi || (!element.edited && !element.created)"
                                            class="w-10 h-6 rounded-md text-center font-bold" />
                                    </div>
                                    <div class="" v-tippy :content="fetchLabel(element.block_details.block_title)">
                                        Block Title: <input :disabled="!element.edited" v-model="element.block_details.block_title"
                                            :class="!element.label ? 'border-b border-dashed border-red-300' : 'border-b border-dashed border-gray-300'"
                                            type="text"
                                            class="bg-transparent w-36 border-0 outline-none p-1 text-md text-gray-600 focus:bg-gray-100 rounded-md overflow-ellipsis" />
                                    </div>
                                    <Button class="btn-success btn-xs" :disabled="!element.name"
                                        @click="handleUpdateBlock(section, element)"
                                        v-if="element.edited && !element.created" text="save" />
                                </div>
                            </div>

                            <div class="flex space-x-4 items-center pr-3">
                                <Button v-if="showSaveBlockButton(element)" @click="saveBlockFields(section, element)"
                                    class="btn btn-xs btn-success border-1 border-solid float-right" text="Save block" />
                                <Button v-if="blockFieldsToSave.includes(element.id)" class="btn-success btn-xs ml-auto"
                                    @click="updateBlockFields(element)" text="Save Fields" />
                                <!-- <button class="outline-none float-right text-gray-800 hover:text-primary-focus" v-if="element.expanded && !hideAddFieldButtonInBlock" @click="showfieldAddModal(section, element)" v-tippy content="Add Field">
                                        <AddFieldIcon class="h-5 w-5 cursor-pointer fill-current" />
                                    </button> -->

                                <span v-if="element.expanded" @click.stop="handleCollapseBlock(section, element)"
                                    class="flex items-center gap-2 cursor-pointer">
                                    <font-awesome-icon icon="chevron-circle-up"
                                        class="text-gray-800 hover:text-primary-focus cursor-pointer text-lg" />
                                </span>
                                <span v-else @click.stop="handleCollapseBlock(section, element)"
                                    class="flex items-center gap-2 cursor-pointer">
                                    <font-awesome-icon icon="chevron-circle-down"
                                        class="text-gray-800 hover:text-primary-focus cursor-pointer text-" />
                                </span>

                                <font-awesome-icon v-if="ISEDITPERMISSIONS && queryData !== 'view'"
                                    @click="handleRemoveBlock(section, element)" icon="times"
                                    class="cursor-pointer text-primary hover:text-primary-focus text-lg" />
                            </div>
                        </div>
                        <div v-if="element.expanded"
                            class="transition-all duration-500 p-4 flex flex-col gap-3 bg-card-bg rounded-b-xl">
                            <p v-if="!element.fields.length" class="flex items-center justify-center text-gray-700">Add
                                Fields to block</p>
                            <!-- <draggable v-else :id="element.id" data-source="juju" :list="element.fields" class="list-group flex flex-col gap-2 transition-all duration-200" draggable=".item" :sort="true" @start="isDragging = true" @change="$emit('handleFieldMove' ,$event)" @end="$emit('handleAddFieldChange' ,$event)"> -->
                            <div v-else :id="element.id" class="list-group flex flex-col gap-2 transition-all duration-200">
                                <draggable v-if="handleRoute()" v-model="element.fields" class="drag-container"
                                    @change="handleFieldReorder($event, element)">
                                    <transition-group type="transition" name="flip-list" class="flex flex-col gap-3">
                                        <div class="list-group-item child item" v-for="blockField in element.fields"
                                            :key="blockField.id">
                                            <template v-if="blockField.is_cascaded" :class="{ 'inner-field': true }">
                                                <CascadedFields :showDeleteIcon="false"
                                                    @saveBlockFields="$emit('saveBlockFields',$event)"
                                                    @showfieldAddModal="$emit('showfieldAddModal',$event)"
                                                    @handleUpdateBlock="$emit('handleUpdateBlock',$event)"
                                                    @handleCollapseBlock="$emit('handleCollapseBlock',$event)"
                                                    @handleFieldReorder="$emit('handleFieldReorder',$event,$event.element)"
                                                    @handleRemoveBlock="$emit('handleRemoveBlock',$event)"
                                                    @handleRemoveSection="$emit('handleRemoveSection',$event)"
                                                    :ISEDITPERMISSIONS="ISEDITPERMISSIONS" fieldClass="bg-gray-100"
                                                    :element="blockField" :section="section" :showSidePanel="showSidePanel"
                                                    :allReportsRoles="allReportsRoles" :activeFieldPanel="activeFieldPanel"
                                                    :updateFieldAttributesMethod="updateFieldAttributesMethod"
                                                    @fieldNameClick="fieldNameClick($event)"
                                                    @handleDeleteField="handleDeleteField($event.element, $event.section)"
                                                    @updateFieldLabel="$emit('updateFieldLabel',$event)"
                                                    @PermissionChange="PermissionChange"
                                                    @showFieldSettingsModal="showFieldSettingsModal(blockField, section)"
                                                    @showGapValidationModal="showGapValidationModal(blockField, section)"
                                                    @changeCaseCreation="changeCaseCreation(blockField)"
                                                    @changeblockVisibile="changeblockVisibile(blockField,true)"
                                                    :draggable="false" 
                                                    :inPackage="true"
                                                    
                                                    :changeMethod="changeMethod"
                                                    :changeCaseCreationMethod="changeCaseCreationMethod"
                                                    />
                                            </template>
                                            <template v-else :class="{ 'inner-field': true }">
                                                <Fields :showDeleteIcon="false" :ISEDITPERMISSIONS="ISEDITPERMISSIONS"
                                                    fieldClass="bg-gray-100" :element="blockField" :section="section"
                                                    :showSidePanel="showSidePanel" :allReportsRoles="allReportsRoles"
                                                    :activeFieldPanel="activeFieldPanel"
                                                    :updateFieldAttributesMethod="updateFieldAttributesMethod"
                                                    @fieldNameClick="fieldNameClick($event)"
                                                    @handleDeleteField="handleDeleteField($event.element, $event.section)"
                                                    @updateFieldLabel="$emit('updateFieldLabel',$event)"
                                                    @PermissionChange="PermissionChange"
                                                    @change="$emit('updateFieldLabel',$event)"
                                                    @changeCaseCreation="changeCaseCreation(blockField)"
                                                    @changeblockVisibile="changeblockVisibile(blockField)"
                                                    @showGapValidationModal="showGapValidationModal(blockField, section)"
                                                    :draggable="false"
                                                    :inPackage="true"
                                                    :changeMethod="changeMethod"
                                                    :changeCaseCreationMethod="changeCaseCreationMethod"
                                                    />
                                            </template>
                                        </div>
                                    </transition-group>
                                </draggable>    

                                <transition-group v-else type="transition" name="flip-list" class="flex flex-col gap-3">
                                    <div class="list-group-item child item" v-for="blockField in element.fields"
                                        :key="blockField.id">
                                        <template v-if="blockField.is_cascaded"
                                        :class="{ 'inner-field': true }">
                                            <CascadedFields :showDeleteIcon="false" :ISEDITPERMISSIONS="ISEDITPERMISSIONS"
                                                fieldClass="bg-gray-100" :element="blockField" :section="section"
                                                :showSidePanel="showSidePanel" :allReportsRoles="allReportsRoles"
                                                :activeFieldPanel="activeFieldPanel"
                                                :updateFieldAttributesMethod="updateFieldAttributesMethod"
                                                @fieldNameClick="fieldNameClick($event)"
                                                @handleDeleteField="handleDeleteField($event.element, $event.section)"
                                                @updateFieldLabel="$emit('updateFieldLabel',$event)"
                                                @PermissionChange="PermissionChange"
                                                @showFieldSettingsModal="showFieldSettingsModal(blockField, section)"
                                                @showGapValidationModal="showGapValidationModal(blockField, section)"
                                                @changeCaseCreation="changeCaseCreation(blockField)"
                                                @changeblockVisibile="changeblockVisibile(blockField,true)" 
                                                :draggable="false" 
                                                :inCheck="inCheck"
                                                :changeMethod="changeMethod"
                                                :changeCaseCreationMethod="changeCaseCreationMethod"
                                                :inFormAdmin="inFormAdmin"
                                                />
                                        </template>
                                        <template v-else :class="{ 'inner-field': true }">
                                            <Fields :showDeleteIcon="false" :ISEDITPERMISSIONS="ISEDITPERMISSIONS"
                                                fieldClass="bg-gray-100" :element="blockField" :section="section"
                                                :showSidePanel="showSidePanel" :allReportsRoles="allReportsRoles"
                                                :activeFieldPanel="activeFieldPanel"
                                                :updateFieldAttributesMethod="updateFieldAttributesMethod"
                                                @fieldNameClick="fieldNameClick($event)"
                                                @handleDeleteField="handleDeleteField($event.element, $event.section)"
                                                @updateFieldLabel="$emit('updateFieldLabel',$event)"
                                                @PermissionChange="PermissionChange"
                                                @change="$emit('updateFieldLabel',$event)"
                                                @changeCaseCreation="changeCaseCreation(blockField)"
                                                @changeblockVisibile="changeblockVisibile(blockField)"
                                                @showGapValidationModal="showGapValidationModal(blockField, section)"
                                                :draggable="false" 
                                                :inCheck="inCheck"
                                                :changeMethod="changeMethod"
                                                :changeCaseCreationMethod="changeCaseCreationMethod"
                                                :inFormAdmin="inFormAdmin"
                                                />
                                        </template>
                                    </div>
                                </transition-group>


                            </div>
                            <!-- </draggable> -->
                            <div class="flex justify-end"></div>
                        </div>
                    </div>

                    <!-- End Block Dragables -->
                </div>
            </transition-group>
        </draggable>
        <transition-group v-else type="transition" name="flip-list" class="flex flex-col space-y-4">
                <div class="list-group-item child item" v-for="element in fields" :key="element.id">
                    {{ log(element) }}
                    <template v-if="element.entity_type === 'field'">
                        <template v-if="element.is_cascaded">
                            {{ log(element) }}
                            <CascadedFields :queryData="queryData" :element="element" :ISEDITPERMISSIONS="ISEDITPERMISSIONS"
                                :section="section" :showSidePanel="showSidePanel" :activeFieldPanel="activeFieldPanel"
                                :allReportsRoles="allReportsRoles"
                                :updateFieldAttributesMethod="updateFieldAttributesMethod"

                                @fieldNameClick="fieldNameClick($event)"
                                @handleDeleteField="handleDeleteField($event.element, $event.section)"
                                @updateFieldLabel="$emit('updateFieldLabel',$event)"
                                @showFieldSettingsModal="showFieldSettingsModal(element, section)"
                                @showGapValidationModal="showGapValidationModal(element, section)"
                                @PermissionChange="PermissionChange"
                                @change="$emit('updateFieldLabel',$event)"
                                @changeCaseCreation="changeCaseCreation(element)"
                                @changeblockVisibile="changeblockVisibile(element,true)" 
                                :inCheck="inCheck"
                                :inPackage="true"
                                :changeMethod="changeMethod"
                                :changeCaseCreationMethod="changeCaseCreationMethod"
                                />
                        </template>
                        <template v-else>
                            <Fields :element="element" :ISEDITPERMISSIONS="ISEDITPERMISSIONS" :section="section"
                                :showSidePanel="showSidePanel" :activeFieldPanel="activeFieldPanel"
                                :allReportsRoles="allReportsRoles"
                                :updateFieldAttributesMethod="updateFieldAttributesMethod"
                                @fieldNameClick="fieldNameClick($event)"
                                @handleDeleteField="handleDeleteField($event.element, $event.section)"
                                @updateFieldLabel="$emit('updateFieldLabel',$event)"
                                @PermissionChange="PermissionChange"
                                @change="$emit('updateFieldLabel',$event)"
                                @changeCaseCreation="changeCaseCreation(element)"
                                @changeblockVisibile="changeblockVisibile(element)"
                                @showGapValidationModal="showGapValidationModal(element, section)" 
                                :inCheck="inCheck"
                                :inPackage="true"
                                :changeMethod="changeMethod"
                                :changeCaseCreationMethod="changeCaseCreationMethod"
                                />
                        </template>
                    </template>

                    <!-- Block Dragables -->
                    <div v-else>
                        <div class="bg-card-bg py-3.5 px-3 text-base-content flex justify-between items-center rounded-t-xl handleUpdateBlock"
                            :class="{
                                'bg-blue-200 text-gray-300': element.created,
                                'rounded-xl': !element.expanded,
                            }">
                            <div class="flex items-center gap-10">
                                <h2 class="font-normal text-sm flex items-center gap-3"
                                    :class="element.created && 'text-primary'">
                                    <span v-tippy :content="fetchLabel(element.name)" v-if="element.name && !element.edited"
                                        class="font-semibold">{{ element.name }}</span>
                                    <input v-model="element.name" v-if="element.edited && !element.created"
                                        :class="!element.name ? 'border-b border-dashed border-red-300' : 'border-b border-dashed border-gray-300'"
                                        type="text"
                                        class="bg-transparent min-w-100 border-0 outline-none p-1 text-md focus:bg-gray-100 rounded-md overflow-ellipsis" />
                                    <div v-if="!element.created && ISEDITPERMISSIONS && queryData !== 'view'"
                                        class="h-8 w-8 flex items-center justify-center rounded-half hover:bg-dvbrandcolor hover:text-white-text transition-all duration-150"
                                        :class="element.edited ? 'bg-dvbrandcolor text-white-text' : 'text-primary'"
                                        @click="handleClick(element)">
                                        <font-awesome-icon icon="edit" class="cursor-pointer text-sm fill-current" />
                                    </div>
                                    <span v-tippy :content="fetchLabel(element.label)">{{ element.label }}</span>
                                </h2>
                                <div class="flex gap-5 items-center pr-10" v-if="element.expanded || element.edited">
                                    <div class="flex items-center gap-2 text-sm">
                                        Multi
                                        <Checkbox @click.native.stop v-model="element.block_multi"
                                            :disabled="!element.edited" />
                                    </div>
                                    <div class="flex items-center gap-2 text-sm">
                                        Min
                                        <input @keypress="isNumber($event)" :min="element.block_multi ? 1 : ''" value="1"
                                            type="number" v-model="element.min_count"
                                            :disabled="!element.block_multi || (!element.edited && !element.created)"
                                            class="w-10 h-6 rounded-md text-center font-bold" />
                                    </div>
                                    <div class="flex items-center gap-2 text-sm">
                                        Max
                                        <input @keypress="isNumber($event)"
                                            :min="element.block_multi ? element.min_count : ''" value="1" type="number"
                                            v-model="element.max_count"
                                            :disabled="!element.block_multi || (!element.edited && !element.created)"
                                            class="w-10 h-6 rounded-md text-center font-bold" />
                                    </div>
                                    <div class="" v-tippy :content="fetchLabel(element.block_details.block_title)">
                                        Block Title: <input v-model="element.block_details.block_title"
                                            :class="!element.label ? 'border-b border-dashed border-red-300' : 'border-b border-dashed border-gray-300'"
                                            type="text"
                                            class="bg-transparent w-36 border-0 outline-none p-1 text-md text-gray-600 focus:bg-gray-100 rounded-md overflow-ellipsis" />
                                    </div>
                                    <Button class="btn-success btn-xs" :disabled="!element.name"
                                        @click="handleUpdateBlock(section, element)"
                                        v-if="element.edited && !element.created" text="save" />
                                </div>
                            </div>

                            <div class="flex space-x-4 items-center pr-3">
                                <Button v-if="showSaveBlockButton(element)" @click="saveBlockFields(section, element)"
                                    class="btn btn-xs btn-success border-1 border-solid float-right" text="Save block" />
                                <Button v-if="blockFieldsToSave.includes(element.id)" class="btn-success btn-xs ml-auto"
                                    @click="updateBlockFields(element)" text="Save Fields" />
                                <!-- <button class="outline-none float-right text-gray-800 hover:text-primary-focus" v-if="element.expanded && !hideAddFieldButtonInBlock" @click="showfieldAddModal(section, element)" v-tippy content="Add Field">
                                        <AddFieldIcon class="h-5 w-5 cursor-pointer fill-current" />
                                    </button> -->

                                <span v-if="element.expanded" @click.stop="handleCollapseBlock(section, element)"
                                    class="flex items-center gap-2 cursor-pointer">
                                    <font-awesome-icon icon="chevron-circle-up"
                                        class="text-gray-800 hover:text-primary-focus cursor-pointer text-lg" />
                                </span>
                                <span v-else @click.stop="handleCollapseBlock(section, element)"
                                    class="flex items-center gap-2 cursor-pointer">
                                    <font-awesome-icon icon="chevron-circle-down"
                                        class="text-gray-800 hover:text-primary-focus cursor-pointer text-" />
                                </span>

                                <font-awesome-icon v-if="ISEDITPERMISSIONS && queryData !== 'view'"
                                    @click="handleRemoveBlock(section, element)" icon="times"
                                    class="cursor-pointer text-primary hover:text-primary-focus text-lg" />
                            </div>
                        </div>
                        <div v-if="element.expanded"
                            class="transition-all duration-500 p-4 flex flex-col gap-3 bg-card-bg rounded-b-xl">
                            <p v-if="!element.fields.length" class="flex items-center justify-center text-gray-700">Add
                                Fields to block</p>
                            <!-- <draggable v-else :id="element.id" data-source="juju" :list="element.fields" class="list-group flex flex-col gap-2 transition-all duration-200" draggable=".item" :sort="true" @start="isDragging = true" @change="$emit('handleFieldMove' ,$event)" @end="$emit('handleAddFieldChange' ,$event)"> -->
                            <div v-else :id="element.id" class="list-group flex flex-col gap-2 transition-all duration-200">
                                <draggable v-if="handleRoute()" v-model="element.fields" class="drag-container"
                                    @change="handleFieldReorder($event, element)">
                                    <transition-group type="transition" name="flip-list" class="flex flex-col gap-3">
                                        <div class="list-group-item child item" v-for="blockField in element.fields"
                                            :key="blockField.id">
                                            <template v-if="blockField.is_cascaded">
                                                <CascadedFields :showDeleteIcon="false"
                                                    @saveBlockFields="$emit('saveBlockFields',$event)"
                                                    @showfieldAddModal="$emit('showfieldAddModal',$event)"
                                                    @handleUpdateBlock="$emit('handleUpdateBlock',$event)"
                                                    @handleCollapseBlock="$emit('handleCollapseBlock',$event)"
                                                    @handleFieldReorder="$emit('handleFieldReorder',$event,$event.element)"
                                                    @handleRemoveBlock="$emit('handleRemoveBlock',$event)"
                                                    @handleRemoveSection="$emit('handleRemoveSection',$event)"
                                                    :ISEDITPERMISSIONS="ISEDITPERMISSIONS" fieldClass="bg-gray-100"
                                                    :element="blockField" :section="section" :showSidePanel="showSidePanel"
                                                    :allReportsRoles="allReportsRoles" :activeFieldPanel="activeFieldPanel"
                                                    :updateFieldAttributesMethod="updateFieldAttributesMethod"
                                                    @fieldNameClick="fieldNameClick($event)"
                                                    @handleDeleteField="handleDeleteField($event.element, $event.section)"
                                                    @updateFieldLabel="$emit('updateFieldLabel',$event)"
                                                    @PermissionChange="PermissionChange"
                                                    @showFieldSettingsModal="showFieldSettingsModal(blockField, section)"
                                                    @showGapValidationModal="showGapValidationModal(blockField, section)"
                                                    @changeCaseCreation="changeCaseCreation(blockField)"
                                                    @changeblockVisibile="changeblockVisibile(blockField,true)"
                                                    :draggable="false" 
                                                    
                                                    :changeMethod="changeMethod"
                                                    :changeCaseCreationMethod="changeCaseCreationMethod"
                                                    />
                                            </template>
                                            <template v-else>
                                                <Fields :showDeleteIcon="false" :ISEDITPERMISSIONS="ISEDITPERMISSIONS"
                                                    fieldClass="bg-gray-100" :element="blockField" :section="section"
                                                    :showSidePanel="showSidePanel" :allReportsRoles="allReportsRoles"
                                                    :activeFieldPanel="activeFieldPanel"
                                                    :updateFieldAttributesMethod="updateFieldAttributesMethod"
                                                    @fieldNameClick="fieldNameClick($event)"
                                                    @handleDeleteField="handleDeleteField($event.element, $event.section)"
                                                    @updateFieldLabel="$emit('updateFieldLabel',$event)"
                                                    @PermissionChange="PermissionChange"
                                                    @change="$emit('updateFieldLabel',$event)"
                                                    @changeCaseCreation="changeCaseCreation(blockField)"
                                                    @changeblockVisibile="changeblockVisibile(blockField)"
                                                    @showGapValidationModal="showGapValidationModal(blockField, section)"
                                                    :draggable="false" 
                                                    :changeMethod="changeMethod"
                                                    :changeCaseCreationMethod="changeCaseCreationMethod"
                                                    />
                                            </template>
                                        </div>
                                    </transition-group>
                                </draggable>    

                                <transition-group v-else type="transition" name="flip-list" class="flex flex-col gap-3">
                                    <div class="list-group-item child item" v-for="blockField in element.fields"
                                        :key="blockField.id">
                                        <template v-if="blockField.is_cascaded">
                                            <CascadedFields :showDeleteIcon="false" :ISEDITPERMISSIONS="ISEDITPERMISSIONS"
                                                fieldClass="bg-gray-100" :element="blockField" :section="section"
                                                :showSidePanel="showSidePanel" :allReportsRoles="allReportsRoles"
                                                :activeFieldPanel="activeFieldPanel"
                                                :updateFieldAttributesMethod="updateFieldAttributesMethod"
                                                @fieldNameClick="fieldNameClick($event)"
                                                @handleDeleteField="handleDeleteField($event.element, $event.section)"
                                                @updateFieldLabel="$emit('updateFieldLabel',$event)"
                                                @PermissionChange="PermissionChange"
                                                @showFieldSettingsModal="showFieldSettingsModal(blockField, section)"
                                                @showGapValidationModal="showGapValidationModal(blockField, section)"
                                                @changeCaseCreation="changeCaseCreation(blockField)"
                                                @changeblockVisibile="changeblockVisibile(blockField,true)" :draggable="false" 
                                                
                                                :changeMethod="changeMethod"
                                                :changeCaseCreationMethod="changeCaseCreationMethod"
                                                />
                                        </template>
                                        <template v-else>
                                            <Fields :showDeleteIcon="false" :ISEDITPERMISSIONS="ISEDITPERMISSIONS"
                                                fieldClass="bg-gray-100" :element="blockField" :section="section"
                                                :showSidePanel="showSidePanel" :allReportsRoles="allReportsRoles"
                                                :activeFieldPanel="activeFieldPanel"
                                                :updateFieldAttributesMethod="updateFieldAttributesMethod"
                                                @fieldNameClick="fieldNameClick($event)"
                                                @handleDeleteField="handleDeleteField($event.element, $event.section)"
                                                @updateFieldLabel="$emit('updateFieldLabel',$event)"
                                                @PermissionChange="PermissionChange"
                                                @change="$emit('updateFieldLabel',$event)"
                                                @changeCaseCreation="changeCaseCreation(blockField)"
                                                @changeblockVisibile="changeblockVisibile(blockField)"
                                                @showGapValidationModal="showGapValidationModal(blockField, section)"
                                                :draggable="false"
                                                :changeMethod="changeMethod"
                                                :changeCaseCreationMethod="changeCaseCreationMethod"
                                                />
                                        </template>
                                    </div>
                                </transition-group>


                            </div>
                            <!-- </draggable> -->
                            <div class="flex justify-end"></div>
                        </div>
                    </div>

                    <!-- End Block Dragables -->
                </div>
        </transition-group>
    </div>
</template>
    
<script>
import Button from "@/components/button";
import CascadedFields from "./CascadedFields.vue";
import Fields from "./Fields.vue";
import draggable from "vuedraggable";
import { isNumber } from "@/plugins/functions";
import Checkbox from "@/components/checkbox-alt";
import { fetchLabel } from "@shared/utils/functions";
const removeEmpty = (obj) => Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null));

export default {
    name: "RecursiveList",
    components: {
        Button,
        Fields,
        CascadedFields,
        draggable,
        Checkbox,
    },
    data() {
        return {
            oldSectionName: "",
            fieldsToSave: false,
            blockFieldsToSave: [],
            inCheck:null,
            isDragging: false,
            inFormAdmin:false
        };
    },
    props: ["section","updateFieldLabel","changeMethod","changeCaseCreationMethod","fields","checkData", "showSidePanel", "activeFieldPanel", "hideAddFieldButtonInBlock", "allReportsRoles", "updateSectionFieldsMethod", "updateBlockFieldsMethod", "updateFieldAttributesMethod", "isUserPermission", "handleeFieldReorder", "queryData", "dragStop"],
    mounted(){
         const currentRoute = window.location.href;
         const parts = currentRoute.split('/')
         if(parts.includes('check-admin')){
         this.inCheck = true
         } else
         {
            this.inCheck = false
         }
         if(parts.includes('form-admin')){
             this.inFormAdmin=true;
         }
    },
    computed: {
        dragOptions() {
            return {
                animation: 0,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
        // Permissions Control
        showAddBlockBtn() {
            if (this.isUserPermission) {
                return (this.isUserPermission.SHOW_ALL_ACTIONS) && (this.isUserPermission.SHOW_BLOCK_ADD_ACTIONS)
            } else {
                return true
            }
        },
        showAddFieldBtn() {
            if (this.isUserPermission) {
                return (this.isUserPermission.SHOW_ALL_ACTIONS) && (this.isUserPermission.SHOW_FIELD_ADD_ACTIONS)
            } else {
                return true
            }
        },
        ISEDITPERMISSIONS() {
            if (this.isUserPermission) {
                return (this.isUserPermission.SHOW_ALL_ACTIONS)
            } else {
                return true
            }
        }
    },
    methods: {
        isNumber,
        fetchLabel,
        log() {

            // console.log('element.is_cascaded', element.is_cascaded, element)
        },
        sectionNameClick(sectionData) {
            this.oldSectionName = sectionData.name;
        },
        startDragging() {
            this.isDragging = true;
        },
        stopDragging() {
            this.isDragging = false;
        },
        showSaveBlockButton(element) {
            if (element.expanded && this.hideAddFieldButtonInBlock) {
                return true
            } else if (element.expanded && element.fields.length && element.entity_type === 'block' && element.created) {
                return true
            } else {
                return false
            }
        },
        async updateSectionLabel(sectionData) {
            if (this.oldSectionName !== sectionData.name) {
                this.$emit("updateSectionLabel", sectionData)
            }
        },
        handleCollapseSection(section) {
            section.expanded = !section.expanded;
        },
        PermissionChange(role) {
            this.$emit("PermissionChange", role);
        },
        changeCaseCreation(payload,flag=false) {
            
            if(typeof this.changeCaseCreationMethod !== 'undefined'){
                        this.changeCaseCreationMethod(payload);
                     }else{
                        this.$emit('changeCaseCreation', payload,flag);

                     }
        },
        changeblockVisibile(payload,flag=false) {
            if(typeof this.changeMethod !== 'undefined'){
                        this.changeMethod(payload);
                     }else{
                        this.$emit('changeblockVisibile', payload,flag);

                     }
            
           
        },

        // section Modal control
        handleRemoveSection() {
            if (this.$route.params.action === 'view') {
                return
            }
            this.$emit("handleRemoveSection", this.section);
        },
        // onShowPanel(field, section) {
        //     this.$emit("handleShowPanel", { field, section });
        // },
        showFieldSettingsModal(field, section) {
            this.$emit("showFieldSettingsModal", { field, section });
        },
        showGapValidationModal(field, section) {
            this.$emit("showGapValidationModal",field, section );
        },

        fieldNameClick(field) {
            this.$emit("fieldNameClick", field);
        },
        handleDeleteField(field, section) {
            if (this.$route.params.action == 'view') {
                return
            }
            this.$emit("handleDeleteField", { field, section });
        },
        // updateFieldLabel(field, section, silent = false) {
        //     if(field){
        //         this.$emit("updateFieldLabel", { field, section, silent });
        //     }
        // },
        handleRemoveBlock(section, element) {
            if (this.$route.params.action === 'view') {
                return
            }
            this.$emit("handleRemoveBlock", { section, element });
        },
        handleFieldReorder(event, element) {
            if (this.$route.params.action == 'view') {
                return
            }
            this.$emit("handleFieldReorder", { event, element })
        },
        handleCollapseBlock(section, element) {
            this.$emit("handleCollapseBlock", { section, element });
        },
        handleUpdateBlock(section, element) {
            if (this.$route.params.action === 'view') {
                return
            }
            if (element.block_multi && (parseInt(element.min_count, 10) > parseInt(element.max_count, 10))) {
                this.$toast.error("Max should be greater than or equal to min");
                return;
            } else
                this.$emit("handleUpdateBlock", { section, element });
        },
        saveBlockFields(section, element) {
            this.$emit("saveBlockFields", { section, element });
        },
        showfieldAddModal(section, element) {
            this.$emit("showfieldAddModal", { section, element });
        },
        fieldChanged(/* block = '' */) { // TODO note where to show save btn
            // if (block) {
            //     if (!this.blockFieldsToSave.includes(block)) this.blockFieldsToSave.push(block);
            // }
            // else this.fieldsToSave = true;
        },
        async updateSectionFields() { // TODO finish when API is ready
            try {
                await this.updateSectionFieldsMethod(this.section);
                this.fieldsToSave = false;
            } catch (error) {
                console.log('Save section fields error', error);
            }
        },
        async updateBlockFields(element) { // TODO finish when API is ready
            try {
                const payload = {
                    ...element,
                    fields: element.fields.map(field => removeEmpty({
                        field_id: field.id,
                        internal_use_only: field.internal_use_only,
                        max_field_or_block_count: field.max_field_or_block_count,
                        min_field_or_block_count: field.min_field_or_block_count,
                        is_top_level: field.is_top_level,
                        report_visibility: field.report_visibility,
                        use_in_gap_validation: field.use_in_gap_validation,
                        history: field.history,
                        valid_gap: field.valid_gap,
                        permissions: field.roles,
                    }))
                };
                await this.updateBlockFieldsMethod(this.section, payload);
                this.blockFieldsToSave = this.blockFieldsToSave.filter(el => el.id !== element.id);
            } catch (error) {
                console.log(error);
                throw error;
            }
        },
        handleClick(element) {
            if (this.$route.params.action !== 'view') {
                console.log('Action is not view. Do something here.');
                element.edited = !element.edited
            } else {
                return
            }
        },
        handleRoute() {
            if (this.$route.name === "Package Tools") {
                return true
            }
            else {
                false
            }
        },
        getDragCondition() {
            if(this.queryData == 'view') {
                return false;
            } else {
                return '.item';
            }
        }

        // End Section Modal control
    },
};
</script>
<style>
.inner-field {
    pointer-events: auto !important;
    cursor: pointer !important; 
  }
</style>
    