<template>
    <div class="text-base-content">
        <div class="flex justify-between items-center">
            <div class="flex items-center space-x-4">
                <GripSectionIcon class="fill-current text-base-content" />
                <span class="text-base-content text-sm font-bold"> Section Name: </span>
                <input v-model="section.name" :disabled="!ISEDITPERMISSIONS || this.$route.params.action == 'view'"
                    @click="sectionNameClick(section)" @blur="updateSectionLabel(section)"
                    :class="!section.name ? 'border border-dashed border-red-300 ' : 'border-b border-dashed border-gray-300'"
                    type="text"
                    class="section_name min-w-100 border-0 outline-none p-1 text-md focus:bg-gray-200 rounded focus:border-none bg-gray-100 overflow-ellipsis"
                    placeholder="ADD SECTION NAME" />
            </div>
            <div class="flex space-x-4 items-center">
                <Button v-if="fieldsToSave" class="btn-success btn-xs" @click="updateSectionFields" text="Save Fields" />
                <button :disabled="this.$route.params.action == 'view'"
                    class="outline-none text-base-content hover:text-primary-focus"
                    v-if="this.$route.params.action !== 'view' && section.expanded && showAddBlockBtn"
                    @click="$emit('showBlockModal', section)" v-tippy content="Add Block">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
                    </svg>
                </button>
                <button :disabled="this.$route.params.action == 'view'"
                    class="outline-none float-right hover:text-primary-focus text-base-content"
                    v-if="this.$route.params.action !== 'view' && section.expanded && showAddFieldBtn"
                    @click="showfieldAddModal(section)" v-tippy content="Add Field">
                    <AddFieldIcon class="h-5 w-5 cursor-pointer fill-current" />
                </button>

                <font-awesome-icon v-if="section.expanded" @click="handleCollapseSection(section)" icon="chevron-circle-up"
                    class="hover:text-primary-focus cursor-pointer text-xl fill-current text-base-content" />

                <font-awesome-icon v-else @click="handleCollapseSection(section)" icon="chevron-circle-down"
                    class="hover:text-primary-focus cursor-pointer text-xl fill-current text-base-content" />

                <font-awesome-icon v-if="this.$route.params.action !== 'view' && ISEDITPERMISSIONS"
                    @click="handleRemoveSection(section)" icon="times"
                    class="cursor-pointer text-primary hover:text-primary-focus text-lg" />
            </div>
        </div>
        <div v-if="section.expanded" class="transition-all duration-200 mt-2">
            <RecursiveList @saveBlockFields="saveBlockFields" @handleFieldMove="$emit('handleFieldMove', $event)"
                @handleAddFieldChange="$emit('handleAddFieldChange', $event)" @showfieldAddModal="showfieldAddModal"
                @handleUpdateBlock="handleUpdateBlock" @handleCollapseBlock="handleCollapseBlock"
                @handleFieldReorder="handleFieldReorder($event, $event.element)" @handleRemoveBlock="handleRemoveBlock"
                @updateFieldLabel="updateFieldLabel" @handleDeleteField="handleDeleteField" @fieldNameClick="fieldNameClick"
                @PermissionChange="PermissionChange" @changeCaseCreation="changeCaseCreation"
                @changeblockVisibile="changeblockVisibile" @handleRemoveSection="handleRemoveSection"
                @showFieldSettingsModal="showFieldSettingsModal" @showGapValidationModal="showGapValidationModal"
                :section="section" :queryData="queryData" :fields="section.fields" :showSidePanel="showSidePanel" :allReportsRoles="allReportsRoles"
                :updateFieldAttributesMethod="updateFieldAttributesMethod" 
                :changeMethod="changeMethod"
                :changeCaseCreationMethod="changeCaseCreationMethod"/>

        </div>

    </div>
</template>

<script>
// import Input from "@/components/input";
import Button from "@/components/button";
import AddFieldIcon from "@/assets/icons/check-admin/add-field.svg";
import { isNumber } from "@/plugins/functions";
import GripSectionIcon from '@/assets/icons/grip-section.svg';
import { fetchLabel } from "@shared/utils/functions";
import RecursiveList from "../RecursiveList.vue";

const removeEmpty = (obj) => Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null));

export default {
    name: "section-dragable",
    components: {
        // Input,
        Button,
        AddFieldIcon,
        GripSectionIcon,
        RecursiveList
    },
    data() {
        return {
            oldSectionName: "",
            fieldsToSave: false,
            blockFieldsToSave: [],
            isDragging: false
        };
    },
    computed: {
        dragOptions() {
            return {
                animation: 0,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
        // Permissions Control
        showAddBlockBtn() {
            if (this.isUserPermission) {
                return (this.isUserPermission.SHOW_ALL_ACTIONS) && (this.isUserPermission.SHOW_BLOCK_ADD_ACTIONS)
            } else {
                return true
            }
        },
        showAddFieldBtn() {
            if (this.isUserPermission) {
                return (this.isUserPermission.SHOW_ALL_ACTIONS) && (this.isUserPermission.SHOW_FIELD_ADD_ACTIONS)
            } else {
                return true
            }
        },
        ISEDITPERMISSIONS() {
            if (this.isUserPermission) {
                return (this.isUserPermission.SHOW_ALL_ACTIONS)
            } else {
                return true
            }
        }
    },
    props: ["section", "checkData", "showSidePanel","activeFieldPanel","changeMethod","changeCaseCreationMethod","hideAddFieldButtonInBlock", "allReportsRoles", "updateSectionFieldsMethod", "updateBlockFieldsMethod", "updateFieldAttributesMethod", "isUserPermission", "handleeFieldReorder", "queryData"],
    methods: {
        isNumber,
        fetchLabel,
        startDragging() {
            this.isDragging = true;
        },
        stopDragging() {
            this.isDragging = false;
        },
        sectionNameClick(sectionData) {
            this.oldSectionName = sectionData.name;
        },
        showSaveBlockButton(element) {
            if (element.expanded && this.hideAddFieldButtonInBlock) {
                return true
            } else if (element.expanded && element.fields.length && element.entity_type === 'block' && element.created) {
                return true
            } else {
                return false
            }
        },
        async updateSectionLabel(sectionData) {
            if (this.oldSectionName !== sectionData.name) {
                this.$emit("updateSectionLabel", sectionData)
            }
        },
        handleCollapseSection(section) {
            section.expanded = !section.expanded;
        },
        PermissionChange(role) {
            this.$emit("PermissionChange", role);
        },
        changeCaseCreation(payload) {
            this.$emit('changeCaseCreation', payload);
        },
        changeblockVisibile(payload,flag=false) {
            this.$emit('changeblockVisibile', payload,flag);
        },

        // section Modal control
        handleRemoveSection() {
            if (this.$route.params.action === 'view') {
                return
            }
            this.$emit("handleRemoveSection", this.section);
        },
        // onShowPanel(field, section) {
        //     this.$emit("handleShowPanel", { field, section });
        // },
        showFieldSettingsModal(field, section) {
            this.$emit("showFieldSettingsModal", { field, section });
        },
        showGapValidationModal(field, section) {
            this.$emit("showGapValidationModal", { field, section });
        },

        fieldNameClick(field) {
            this.$emit("fieldNameClick", field);
        },
        handleDeleteField(field, section) {
            if (this.$route.params.action == 'view') {
                return
            }
            this.$emit("handleDeleteField", { field, section });
        },
        updateFieldLabel(field, section, silent = false) {
            section = section || field?.section
            field = field?.field || field
            this.$emit("updateFieldLabel", { field, section, silent });
        },
        handleRemoveBlock({section, element}) {
            if (this.$route.params.action === 'view') {
                return
            }
            this.$emit("handleRemoveBlock", { section, element });
        },
        handleFieldReorder(event, element) {

            if (this.$route.params.action == 'view') {
                return
            }
            this.$emit("handleFieldReorder", { event, element })
        },

        handleCollapseBlock(section, element) {
            element = element || section?.element
            section = section?.section || section
            this.$emit("handleCollapseBlock", { section, element });
        },
        handleUpdateBlock(section, element) {
            element = element || section?.element
            section = section?.section || section
            if (this.$route.params.action === 'view') {
                return
            }
            if (element.block_multi && (parseInt(element.min_count, 10) > parseInt(element.max_count, 10))) {
                this.$toast.error("Max should be greater than or equal to min");
                return;
            } else
                this.$emit("handleUpdateBlock", { section, element });
        },
        saveBlockFields(section, element) {
            this.$emit("saveBlockFields", { section, element });
        },
        showfieldAddModal(section, element) {
            this.$emit("showfieldAddModal", { section, element });
        },

        fieldChanged(/* block = '' */) { // TODO note where to show save btn
            // if (block) {
            //     if (!this.blockFieldsToSave.includes(block)) this.blockFieldsToSave.push(block);
            // }
            // else this.fieldsToSave = true;
        },
        async updateSectionFields() { // TODO finish when API is ready
            try {
                await this.updateSectionFieldsMethod(this.section);
                this.fieldsToSave = false;
            } catch (error) {
                console.log('Save section fields error', error);
            }
        },
        async updateBlockFields(element) { // TODO finish when API is ready
            try {
                const payload = {
                    ...element,
                    fields: element.fields.map(field => removeEmpty({
                        field_id: field.id,
                        internal_use_only: field.internal_use_only,
                        max_field_or_block_count: field.max_field_or_block_count,
                        min_field_or_block_count: field.min_field_or_block_count,
                        is_top_level: field.is_top_level,
                        report_visibility: field.report_visibility,
                        use_in_gap_validation: field.use_in_gap_validation,
                        history: field.history,
                        valid_gap: field.valid_gap,
                        permissions: field.roles,
                    }))
                };
                await this.updateBlockFieldsMethod(this.section, payload);
                this.blockFieldsToSave = this.blockFieldsToSave.filter(el => el.id !== element.id);
            } catch (error) {
                console.log(error);
                throw error;
            }
        },
        handleClick(element) {
            if (this.$route.params.action !== 'view') {
                console.log('Action is not view. Do something here.');
                element.edited = !element.edited
            } else {
                return
            }
        },
        handleRoute() {
            if (this.$route.name === "Package Tools") {
                return true
            }
            else {
                false
            }
        }

        // End Section Modal control
    },
};
</script>

<style>
.draggable-disabled {
    pointer-events: none;
}

.inner-field {
    pointer-events: auto !important;
    cursor: pointer !important; 
  }
</style>
